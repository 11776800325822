import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BookingState } from '../stateModel/stateModel';
import { initialSelectedOffer } from '../../dto/SelectedOfferDto';
import { initialExperience } from '../../models/Experience';

const initialState: BookingState = {
  date: null,
  time: null,
  groupSize: 1,
  childrenSize: 0,
  selectedOffer: initialSelectedOffer,
  customers: [],
  finalTotal: 0,
  originalTotal: 0,
  isBooked: false,
  eventDetails: {
    eventName: 'PARIS BOURSE',
    venueId: 1
  },
  selectedExperience: initialExperience,
  error: ''
};



const bookingSlice = createSlice({
  name: 'booking',
  initialState,
  reducers: {
    updateBookingDetails: (
      state,
      action: PayloadAction<Partial<BookingState>>
    ) => {
      return { ...state, ...action.payload };
    },
    resetBooking: () => initialState
  },
});

export const { updateBookingDetails, resetBooking } = bookingSlice.actions;
export default bookingSlice.reducer;