import { createSlice, createAsyncThunk, PayloadAction, createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import apiClient from '../../services/apiClient'; // Assuming you have an API client
import { Offer } from '../../models/offer';

// Define types for offer and state


interface OfferState {
  offers: Offer[];
  selectedOfferId: number | null;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: OfferState = {
  offers: [],
  selectedOfferId: null,
  status: 'idle',
  error: null,
};

export const fetchOffers = createAsyncThunk<Offer[], void, { rejectValue: string }>(
  'offers/fetchOffers',
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiClient.get('/offers');
      return response.data.offers;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const offerSlice = createSlice({
  name: 'offers',
  initialState,
  reducers: {
    setSelectedOfferId: (state, action: PayloadAction<number>) => {
      state.selectedOfferId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOffers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchOffers.fulfilled, (state, action: PayloadAction<Offer[]>) => {
        state.status = 'succeeded';
        state.offers = action.payload;
      })
      .addCase(fetchOffers.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });
  },
});

export const { setSelectedOfferId } = offerSlice.actions;

export const selectAllOffers = (state: RootState) => state.offers.offers;
export const selectOfferById = (state: RootState, offerId: number) =>
  state.offers.offers.find((offer) => offer.id === offerId);
export const selectSelectedOffer = (state: RootState) => state.offers.selectedOfferId;

export const selectOfferDurations = createSelector(
  [
    (state: RootState, offerId: number) => selectOfferById(state, offerId), // Only need offerId
    (_, __, experienceId: number) => experienceId // Extract experienceId
  ],
  (offer, experienceId) => {
    if (!offer) return [];

    // Find the experience inside the offer
    const experience = offer.experiences.find((exp: any) => exp.id === experienceId);
    if (!experience) return [];

    return experience.durations || [];
  }
);

export default offerSlice.reducer;