import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tab, TabGroup, TabList, TabPanels, TabPanel } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faCalendarCheck, faCalendarPlus, faClock, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { AppDispatch, RootState } from "../../store";
import { Link, useNavigate } from "react-router-dom";
import { fetchBookingsMember, setSelectedBooking } from "../../store/bookingMemberSessions/bookingMemberSlice";
import { Customer } from "../../models/customer";
import { Booking } from "../../models/booking";


interface BookingListProps {
  selectedClient: Customer;
}

const BookingList: React.FC<BookingListProps> = ({ selectedClient }) => {
  const dispatch = useDispatch<AppDispatch>();
  const bookings = useSelector((state: RootState) => state.bookingMembeSession.bookings);
  const loading = useSelector((state: RootState) => state.bookingMembeSession.loading);
  const [selectedTab, setSelectedTab] = useState(0);
   const router = useNavigate();

  // Fetch bookings on mount
  useEffect(() => {
    if (selectedClient.id) {
      dispatch(fetchBookingsMember(selectedClient.id));
    }
  }, [dispatch, bookings.length]);  

  // Filter Upcoming Sessions
  const upcomingSessions = bookings
  .flatMap((booking) => 
    booking.sessions?.map((session) => ({ ...session, booking })) || [] 
  )
  .filter((session) => new Date(session.startTime) >= new Date());

  // Filter Past Sessions
  const pastSessions = bookings
  .flatMap((booking) => 
    booking.sessions?.map((session) => ({ ...session, booking })) || [] 
  )
  .filter((session) => new Date(session.startTime) < new Date());



  const handleBookSession = (booking: Booking, session: any) => {
      dispatch(setSelectedBooking(booking));
      router(`/espace-member/booking/${booking.id}/session/${session.bookingSessionId}`);
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <h2 className="text-xl font-bold mb-4">
        <FontAwesomeIcon icon={faCalendarAlt} className="mr-2" /> Mes Réservations
      </h2>

      {loading ? (
        <p className="text-gray-500">Chargement des réservations...</p>
      ) : (
        <TabGroup selectedIndex={selectedTab} onChange={setSelectedTab}>
          {/* Tabs */}
          <TabList className="flex space-x-2 border-b">
            <Tab className={({ selected }) => `p-2 text-sm font-medium ${selected ? "text-gold border-b-2 border-gold" : "text-gray-500"}`}>
              À venir
            </Tab>
            <Tab className={({ selected }) => `p-2 text-sm font-medium ${selected ? "text-gold border-b-2 border-gold" : "text-gray-500"}`}>
              Passées
            </Tab>
          </TabList>

          {/* Panels */}
          <TabPanels>
          {/* Upcoming Sessions */}
          <TabPanel>
            {upcomingSessions.length === 0 ? (
              <p className="mt-4  text-gray-500">Aucune réservation à venir.</p>
            ) : (
              <ul className="mt-4 space-y-4">
                {upcomingSessions.map(({ booking, ...session }) => (
                  <li key={session.bookingSessionId} className="border p-3 rounded-lg flex justify-between items-center">
                    <div>
                      <h3 className="text-lg font-bold">{booking.offer.name} - {booking.offer.experience.name}</h3>
                      <p className="text-sm text-gray-500">
                        <FontAwesomeIcon icon={faCalendarCheck} className="mr-2" />
                        {session.startTime} - {session.endTime}
                      </p>
                      <p className="text-sm text-gray-500">
                        <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2" />
                        {booking.venue.name}
                      </p>
                    </div>
                    <div className="flex items-center space-x-4">
                      <button onClick={() => handleBookSession(booking, session)} className="bg-gold text-white px-4 py-2 rounded-md flex items-center gap-2 hover:bg-black hover:text-gold transition">
                        <FontAwesomeIcon icon={faCalendarPlus} />
                        Modifier mon créneau
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </TabPanel>

          {/* Past Sessions */}
          <TabPanel>
            {pastSessions.length === 0 ? (
              <p className="text-gray-500 mt-4 ">Aucune réservation passée.</p>
            ) : (
              <ul className="mt-4 space-y-4">
                {pastSessions.map(({ booking, ...session }) => (
                  <li key={session.bookingSessionId} className="border p-3 rounded-lg flex justify-between items-center">
                    <div>
                      <h3 className="text-lg font-bold">{booking.offer.name} - {booking.offer.experience.name}</h3>
                      <p className="text-sm text-gray-500">
                        <FontAwesomeIcon icon={faClock} className="mr-2" />
                        {session.startTime} - {session.endTime}
                      </p>
                      <p className="text-sm text-gray-500">
                        <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2" />
                        {booking.venue.name}
                      </p>
                    </div>
                    <span className="text-gray-500">Complétée</span>
                  </li>
                ))}
              </ul>
            )}
          </TabPanel>
        </TabPanels>
        </TabGroup>
      )}
    </div>
  );
};

export default BookingList;