// src/components/BookingForm.tsx
import React, { useState } from 'react';
import LoadingOverlay from '../loadingOverlay/LoadingOverlay';
import { bookedError, bookOffer } from '../../utils/booked';
import { Offer } from '../../models/offer';
import { SelectedOfferDto } from '../../dto/SelectedOfferDto';

// Define types for props


interface BookingFormProps {
  customers: CustomerDto[];
  eventDetails: EventDetails;
  bookingdate: string;
  groupSize: number;
  childrenSize: number;
  offer: SelectedOfferDto;
  onBookingSuccess: (bookingData: any) => void;
}

const BookingForm: React.FC<BookingFormProps> = ({
  customers,
  eventDetails,
  bookingdate,
  groupSize,
  childrenSize,
  offer,
  onBookingSuccess,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      const bookingData = await bookOffer({
        customers,
        eventDetails,
        bookingdate,
        groupSize,
        childrenSize,
        offer,
      });
      onBookingSuccess(bookingData);
    } catch (error) {
      const messageError = bookedError(error);
      setError(messageError);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="my-4">
      <button
        type="submit"
        className="w-full bg-black text-gold hover:bg-gold hover:text-black py-2 px-4"
        disabled={loading}
      >
        {loading ? 'Réservation en cours...' : 'Réserver'}
      </button>
      {error && <p className="mt-2 text-sm text-red-500">{error}</p>}
      <LoadingOverlay isLoading={loading} />
    </form>
  );
};

export default BookingForm;