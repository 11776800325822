import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';
import { useNavigate } from 'react-router-dom';
import LoadingOverlay from '../components/loadingOverlay/LoadingOverlay';
import { initialSelectedOffer, SelectedOfferDto } from '../dto/SelectedOfferDto';
import { fetchOffers, selectAllOffers } from '../store/offer/offerSlice';
import { updateBookingDetails } from '../store/booking/bookingSlice';
import OfferCard from '../components/experience/OfferCard';

const ExperiencePage: React.FC = () => {
  
  const [selectedExperience, setSelectedExperience] = useState<{ id: number; name: string } | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedOffer, setSelectedOffer] = useState<SelectedOfferDto>(initialSelectedOffer);
  const bookingDetails = useSelector((state: RootState) => state.booking);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const apiOffers = useSelector(selectAllOffers);

  useEffect(() => {
    if (apiOffers.length === 0) {
      dispatch(fetchOffers()); // Fetch offers when the component mounts
    }
  }, [dispatch, apiOffers.length]);

  // Transform API data into required format
  const { experiences, offers } = useMemo(() => {
    const experienceMap = new Map();
    const transformedOffers:any = [];

    apiOffers.forEach((offer) => {
      const offerId = offer.id;

      const selectedExperienceData = offer.experiences.find((exp:any) => exp.id === selectedExperience?.id) || offer.experiences[0];;
      console.log('selectedExperienceData', selectedExperienceData);
      if (!selectedExperienceData) return;


      const bestDuration = selectedExperienceData?.durations?.reduce((min: any, d: any) =>
        d.minutes < min.minutes ? d : min, selectedExperienceData?.durations[0]
      );

      // Create transformed offer object
      const transformedOffer = {
        id: offerId,
        name: offer.name,
        description: offer.description || "",
        age: "10+",
        duration: bestDuration?.minutes || 30,
        price: bestDuration?.pricing_options?.[0]?.price || "N/A",
        image: offer.name.toLowerCase().replace(/\s+/g, "-"), // Generate image filename
        bestSeller: offer.bestSeller || false,
        is_kid_friendly: offer.is_kid_friendly,
        is_pack: offer.is_pack,
        min_driver: offer.min_driver,
        simulator_count: Number(offer.simulator_count),
        session_count: bestDuration?.pricing_options?.[0]?.session_count || 1,
        duration_id: bestDuration?.id,
        experience_id: selectedExperience?.id,
        offer_pricing_id: bestDuration?.pricing_options?.[0]?.offer_pricing_id
      };

      transformedOffers.push(transformedOffer);
      console.log('transformedOffers', transformedOffers);

      // Process experiences for this offer
      offer.experiences.forEach((exp: any) => {
        if (!experienceMap.has(exp.id)) {
          experienceMap.set(exp.id, { id: exp.id, name: exp.name, offers: [] });
        }
        experienceMap.get(exp.id).offers.push(offerId);
      });
    });

    return { experiences: Array.from(experienceMap.values()), offers: transformedOffers };
  }, [apiOffers, selectedExperience]);

  useEffect(() => {
    if (experiences.length > 0 && !selectedExperience) {
      setSelectedExperience({ id: experiences[0].id, name: experiences[0].name });
    }
  }, [experiences, selectedExperience]);

  console.log("Experiences:", experiences);
  console.log("Offers:", offers);


  useEffect(() => {
    if (selectedExperience) {
      const newOffer = offers.find((o:any) => o.id === selectedOffer.id);
      if (newOffer) {
        setSelectedOffer({ ...newOffer });
      }
    }
  }, [selectedExperience, offers]);



    const handleOfferSelection = (selectedOffer: any) => {
        setLoading(true);
        //const { id, name, price, duration, is_kid_friendly, simulator_count } = selectedOffer;
        const selectedOfferWithExperience =  {
          ...selectedOffer,
          experience_id: selectedExperience?.id,
        }

        setSelectedOffer({ ...selectedOfferWithExperience });

        dispatch(updateBookingDetails({
          ...bookingDetails,
          ...(selectedExperience  && { selectedExperience }),
          selectedOffer
        }));

        navigate('/booking')

        console.log("Selected Center in experience:", selectedOfferWithExperience);
    };

    const filteredOffers = offers.filter((offer: any) =>
      selectedExperience &&
      experiences.find((exp) => exp.id === selectedExperience.id)?.offers.includes(offer.id)
    );

  return (
      <>
        <div className="">
          <h1 className="text-4xl font-bold text-center mb-10 uppercase">NOS EXPÉRIENCES</h1>

          <div className="max-w-7xl mx-auto px-6">
          <div className="flex space-x-4 mb-16 justify-center">
            {experiences && experiences.map((exp) => (
              <button
                key={exp.id}
                onClick={() => setSelectedExperience({ id: exp.id, name: exp.name })}
                className={`px-6 py-4 text-lg font-bold uppercase rounded-md transition flex items-center gap-3 shadow-md ${
                  selectedExperience?.id === exp.id
                    ? "bg-gold text-white scale-105 shadow-lg"
                    : "bg-black text-white hover:bg-gold hover:text-white hover:scale-105"
                }`}
              >
                {/* Example icons for experiences */}
                {exp.name}
              </button>
            ))}
          </div>

            {/* Offers Grid */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {filteredOffers.map((offer: any, index: number) => (
                <OfferCard  onOfferChange={handleOfferSelection} key={offer.id}  offer={offer} index={index}/>
              ))}
            </div>
          </div>

    
        </div>
        <LoadingOverlay isLoading={loading} />
      </>
  );
};

export default ExperiencePage;