import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store";
import { fetchClients, selectClient } from "../store/client/clientListSlice";
import { EyeIcon } from "@heroicons/react/24/solid";
import Lightbox from "../components/lightbox/Lightbox";
import { Customer } from "../models/customer";
import ClientModal from "../components/client/ClientModal";
import { useNavigate } from "react-router-dom";

const ClientsPage: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const { clients, loading, error } = useSelector((state: RootState) => state.clients);
  
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedClient, setSelectedClient] = useState<Customer | null>(null);
  
  const itemsPerPage = 10;

  useEffect(() => {
    dispatch(fetchClients());
  }, [dispatch]);

  const filteredClients = clients.filter((client: any) =>
    `${client.firstname?.toLowerCase() || ""} ${client.lastname?.toLowerCase() || ""}`.includes(searchTerm.toLowerCase()) ||
    client.email?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalPages = Math.ceil(filteredClients.length / itemsPerPage);
  const currentItems = filteredClients.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const openModal = (client: Customer) => {
    setSelectedClient(client);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedClient(null);
  };

  const handleAccessEspace = (client: any) => {
    dispatch(selectClient(client));
    navigate('/espace-member');
  };

  return (
    <div className="max-w-7xl mx-auto py-6 px-4">
      {/* Search Bar */}
      <div className="flex flex-col md:flex-row justify-between items-center mb-6 space-y-4 md:space-y-0">
        <input 
          type="text" 
          placeholder="Rechercher un client..."
          value={searchTerm} 
          onChange={(e) => setSearchTerm(e.target.value)} 
          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-gold focus:outline-none"
        />
      </div>

      {/* Clients Table */}
      <div className="bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase">Nom</th>
                <th className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase">Civilité</th>
                
                <th className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase">Email</th>
                <th className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase">Téléphone</th>
                <th className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase">Date de Naissance</th>
                <th className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase">Date d'Inscription</th>
                <th className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((client) => (
                <tr key={client.id}>
                  <td className="px-6 py-4 text-sm text-gray-500">
                    {client.firstname} {client.lastname}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-500">{client.civilite}</td>
                  <td className="px-6 py-4 text-sm text-gray-500">{client.email}</td>
                  <td className="px-6 py-4 text-sm text-gray-500">{client.tel}</td>
                  <td className="px-6 py-4 text-sm text-gray-500">
                  {client.birthday ? new Date(client.birthday).toLocaleDateString("fr-FR") : "N/A"}
                </td>
                <td className="px-6 py-4 text-sm text-gray-500">
                  {client.created_at ? new Date(client.created_at).toLocaleDateString("fr-FR") : "N/A"}
                </td>
                  <td className="px-6 py-4 text-sm">
                    <div className="flex">
                      <button onClick={() => openModal(client)} className="text-blue-500 hover:text-blue-700">
                        <EyeIcon className="h-4 w-4" />
                      </button>
                      <button 
                        onClick={() => handleAccessEspace(client)} 
                        className="ml-2 px-6 py-2 bg-gold text-white rounded-lg hover:bg-black hover:text-gold  transition"
                      >
                        Accès Espace Client
                      </button>
                    </div>
                    
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Pagination */}
      <div className="flex justify-between items-center mt-6">
        <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}
          className="px-4 py-2 bg-gold text-white rounded-lg hover:bg-black disabled:bg-gray-100 disabled:text-gray-500">
          Précédent
        </button>
        <span className="text-sm">Page {currentPage} sur {totalPages}</span>
        <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}
          className="px-4 py-2 bg-gold text-white rounded-lg hover:bg-black disabled:bg-gray-100 disabled:text-gray-500">
          Suivant
        </button>
       
      </div>

      {/* Client Details Modal */}
      <Lightbox isOpen={isModalOpen} onClose={closeModal} title="Détails du Client">
        {selectedClient && <ClientModal client={selectedClient} />}
      </Lightbox>
    </div>
  );
};

export default ClientsPage;