import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import {  useNavigate, useParams } from 'react-router-dom';
import { BookingSession } from '../models/bookingSession';
import BookingSessionForm from '../components/booking-sessions/BookingSessionForm';
import { BookingFormProps } from '../models/bookingFormProps';

const SessionBookingPage: React.FC = () => {
  // Access currentBooking directly from the state
  const { currentBooking } = useSelector((state: RootState) => state.bookingList);
  const navigate = useNavigate();
  const { bookingId, sessionId } = useParams<{ bookingId: string; sessionId?: string }>();
  const [selectedSession, setSelectedSession] = useState<BookingSession | null>(null);
  const [selectedBooking, setSelectedBooking] = useState<BookingFormProps | null>(null);

  console.log("Booking ID:", bookingId);
  console.log("Session ID:", sessionId);

  const handleBookingSessionSuccess = async (bookingData: any) => {
    navigate(`/booking-details/${bookingData.id}`); 
  }

  useEffect(() => {
    let notAllowedToAcces = false;
    if (!currentBooking) {
      notAllowedToAcces = true
    } else {
      setSelectedBooking(
        {
          id: currentBooking.id,
          offer_pricing_id: currentBooking.offer_pricing_id,
          duration_time: currentBooking.offer.duration,
          groupSize: currentBooking.groupSize,
        }
      )
    }

    if (currentBooking && currentBooking.sessions && sessionId) {
      const session = currentBooking.sessions.find((s) => s.bookingSessionId === Number(sessionId));
      console.log('session', session);
      if (!session) {
        notAllowedToAcces = true
      } else {
        setSelectedSession(session);
      }
    }

    if (notAllowedToAcces) {
      navigate(`/booking-details/${bookingId}`); 
    }

  }, [currentBooking, navigate, bookingId, sessionId])


  if (!currentBooking && !selectedBooking) {
    return <div>Loading...</div>;
  }

  return (
   <BookingSessionForm
      currentBooking = {selectedBooking}
      selectedSession={selectedSession}
      backLink={`/booking-details/${currentBooking?.id}`}
      onBookingSessionSuccess={handleBookingSessionSuccess}
   />
  );
};

export default SessionBookingPage;