import './App.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import BookingCalendarContainer from './containers/BookingCalendarContainer';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import BookingPage from './pages/BookingPage';
import CartPage from './pages/CartPage';
import OrderConfirmationPage from './pages/OrderConfirmationPage';
import CheckoutPage from './pages/CheckoutPage';
import CheckoutFailedPage from './pages/CheckoutFailedPage';
import PrivateRoute from './PrivateRoute';
import DashboardPage from './pages/Dashboard';
import BookingDetailsPage from './pages/BookingDetailsPage';
import ParticipantsPage from './pages/ParticipantsPage';
import ExperiencePage from './pages/ExperiencePage';
import SessionBookingPage from './pages/SessionBookingPage';
import ClientsPage from './pages/ClientsPage';
import EspaceMemberDashboard from './pages/espace-member/EspaceMemberDashboard';
import SessionMemberBookingPage from './pages/espace-member/SessionMemberBookingPage';

function App() {
  return (
      <Router>
            <Routes>
             <Route path="login" element={<LoginPage />}  />
             <Route path="/" element={<PrivateRoute><HomePage /></PrivateRoute>}>
                  <Route index element={<DashboardPage />} />
                  <Route path="experience" element={<ExperiencePage />} />
                  <Route path="booking" element={<BookingPage />} />
                  <Route path="bookinglist" element={<BookingCalendarContainer />} />
                  <Route path="cart" element={<CartPage />} /> 
                  <Route path="checkout" element={<CheckoutPage />} />   
                  <Route path="order-confirmation" element={<OrderConfirmationPage />} /> 
                  <Route path="checkout-failed" element={<CheckoutFailedPage />} /> 
                  <Route path="booking-details/:bookingId" element={<BookingDetailsPage />} />
                  <Route path="booking/:bookingId/participants/:sessionId" element={<ParticipantsPage />} />
                  <Route path="booking/:bookingId/schedule-session" element={<SessionBookingPage />} />
                  <Route path="booking/:bookingId/session/:sessionId" element={<SessionBookingPage />} />
                  <Route path="clients" element={<ClientsPage />} />
                  <Route path="espace-member" element={<EspaceMemberDashboard />} />
                  <Route path="espace-member/booking/:bookingId/schedule-session" element={<SessionMemberBookingPage />} />
                  <Route path="espace-member/booking/:bookingId/session/:sessionId" element={<SessionMemberBookingPage />} />
                </Route>
             </Routes>    
      </Router> 
  );
}

export default App;
