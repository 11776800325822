import Sidebar from "../../components/espace-member/Sidebar";

interface EspaceLayoutProps {
  children: React.ReactNode;
}

const EspaceLayout = ({ children }: EspaceLayoutProps) => {
  return (
    <div className="flex min-h-screen bg-white">
      <div className="container max-w-7xl  mx-auto flex text-black">
        {/* Sidebar - Fixed width 
        <Sidebar />*/}

        {/* Main Content - Push content to the right */}
        <div className="flex-1 p-12">{children}</div>
      </div>
    </div>
  );
};

export default EspaceLayout;