// src/components/BookingDetails/BookingDetails.tsx
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Booking } from "../../../models/booking";
import { formatTimeWithoutZone } from "../../../utils/formatDateTime";
import { ArrowLongLeftIcon } from "@heroicons/react/24/solid";

interface BookingDetailsProps {
  booking: Booking;
  bookingId: string;
}

const BookingDetails: React.FC<BookingDetailsProps> = ({ booking, bookingId }) => {
  const formatDate = (dateString: string) => new Date(dateString).toLocaleDateString("fr-FR");
  const navigate = useNavigate();


  const handleEditTime = () => {
    navigate(`/booking/${bookingId}/change-time`);
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-10">
        <h2 className="text-xl font-bold mb-4">Détails de la Réservation</h2>
        <Link to="/" className="text-gold hover:text-black flex items-center gap-x-1">
          <ArrowLongLeftIcon className="h-4 w-4" /> Retour
        </Link>
      </div>

      {/* Informations du client */}
      <div className="bg-white p-6 rounded-lg shadow-md mb-6">
        <h2 className="text-xl font-bold text-gray-700 mb-4">Informations du client</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <div>
            <p className="font-semibold text-gray-600">Nom:</p>
            <p className="text-gray-700">{booking.customer.firstname} {booking.customer.lastname}</p>
          </div>
          <div>
            <p className="font-semibold text-gray-600">Email:</p>
            <p className="text-gray-700">{booking.customer.email}</p>
          </div>
          <div>
            <p className="font-semibold text-gray-600">Téléphone:</p>
            <p className="text-gray-700">{booking.customer.tel}</p>
          </div>
        </div>
      </div>

      {/* Informations de la réservation */}
      <div className="bg-white p-6 rounded-lg shadow-md mb-6">
        <h2 className="text-xl font-bold text-gray-700 mb-4">Informations de la réservation</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <div>
            <p className="font-semibold text-gray-600">Date de Session:</p>
            <p className="text-gray-700">{booking.sessions.length > 0 ? formatDate(booking.sessions[0].startTime) : "Non planifiée"}</p>
          </div>
          <div>
            <p className="font-semibold text-gray-600">Session:</p>
            <p className="text-gray-700">{booking.offer.name}</p>
          </div>
          <div>
            <p className="font-semibold text-gray-600">Expérience:</p>
            <p className="text-gray-700">{booking.offer.experience.name}</p>
          </div>
          <div>
            <p className="font-semibold text-gray-600">Durée:</p>
            <p className="text-gray-700">{booking.offer.duration} min</p>
          </div>
          <div>
            <p className="font-semibold text-gray-600">Nombre de pilotes:</p>
            <p className="text-gray-700">{booking.groupSize} pilote(s)</p>
          </div>
          <div>
            <p className="font-semibold text-gray-600">Sessions utilisées: {booking.sessions_booked}/{booking.total_sessions}</p>
          </div>
        </div>
      </div>

      {/* Sessions List */}
      <div className="bg-white p-6 rounded-lg shadow-md mb-6">
        <h2 className="text-xl font-bold text-gray-700 mb-4">Sessions de la réservation</h2>

        {booking.sessions.length === 0 ? (
          <p className="text-gray-700 italic">Aucune session planifiée pour le moment.</p>
        ) : (
          booking.sessions.map((session, index) => (
            <div key={session.bookingSessionId} className="border p-3 rounded-lg bg-gray-100 flex justify-between items-center mb-2">
              <div>
                <p className="font-semibold text-gray-600">Session {index + 1}:</p>
                <p className="text-gray-700">
                  <span className="font-semibold">Date:</span> {formatDate(session.startTime)}
                </p>
                <p className="text-gray-700">
                  <span className="font-semibold">Heure:</span> {formatTimeWithoutZone(session.startTime)}
                </p>
              </div>
              <div className="flex gap-4">
              <button
                onClick={() => navigate(`/booking/${bookingId}/session/${session.bookingSessionId}`)}
                className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-700"
              >
                Modifier session
              </button>
              <button
               onClick={() => navigate(`/booking/${bookingId}/participants/${session.bookingSessionId}`)}
                className="bg-gray-700 text-white px-6 py-2 rounded-lg hover:bg-black"
              >
                Participants
              </button>

              </div>

            </div>
          ))
        )}



        {/* Button to Planify New Session */}
        {booking.sessions_remaining > 0 && (
          <button
            onClick={() => navigate(`/booking/${bookingId}/schedule-session`)}
            className="bg-green-500 text-white px-6 py-2 rounded-lg hover:bg-green-700 mt-4"
          >
            Planifier session
          </button>
        )}
      </div>

    </div>
  );
};

export default BookingDetails;