import React, { useEffect, useMemo, useRef, useState } from 'react';
import { RadioGroup, Radio } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/24/solid';

// Types for the component props
interface DurationProps {
  onDurationChange: (duration: any) => void;
  offerDurations: any;
}

const Duration: React.FC<DurationProps> = ({ onDurationChange, offerDurations }) => {

  

  const sortedDurations = useMemo(() => 
    offerDurations.map((duration: any) => ({
      ...duration,
      pricing_options: [...duration.pricing_options].sort((a, b) => a.session_count - b.session_count),
    })), 
    [offerDurations]
  );

  const [selectedDuration, setSelectedDuration] = useState<any>(null);
  const selectedInitially = useRef(false);

  useEffect(() => {
    if (!selectedInitially.current && sortedDurations.length > 0) {
      const firstDuration = sortedDurations[0]; // Première durée
      const firstOption = firstDuration?.pricing_options[0]; // Premier prix
  
      if (firstDuration && firstOption) {
        const defaultSelection = {
          id: firstDuration.id,
          minutes: firstDuration.minutes,
          price: firstOption.price,
          session_count: firstOption.session_count,
          offer_pricing_id: firstOption.offer_pricing_id
        };

        console.log('defaultSelection', sortedDurations[0])
  
        setSelectedDuration(defaultSelection);
        onDurationChange(defaultSelection);
        selectedInitially.current = true;
      }
    }
  }, [sortedDurations, onDurationChange]);

  const handleDurationSelection = (duration: any, option: any) => {
    const selected = {
      id: duration.id,
      minutes: duration.minutes,
      price: option.price,
      session_count: option.session_count,
      offer_pricing_id: option.offer_pricing_id
    };

    setSelectedDuration(selected);
    onDurationChange(selected);
  };

  

  

  

  

  return (
    <div className="space-y-2">
      <div className="mx-auto w-full font-heading">
      <RadioGroup value={selectedDuration} className="flex sm:flex-row flex-wrap gap-3 flex-col">
          {sortedDurations.map((duration: any) =>
             duration.pricing_options.map((option: any) => {
              const isSelected = selectedDuration?.id === duration.id && selectedDuration?.price === option.price;


              return (
                <Radio
                  key={`${duration.id}-${option.session_count}`}
                  value={{ durationId: duration.id, sessionCount: option.session_count }}
                  onClick={() => handleDurationSelection(duration, option)}
                  className={`group relative flex cursor-pointer rounded-lg shadow-md transition px-3 py-3 ${
                    isSelected ? "bg-black text-gold" : "bg-gold text-black hover:bg-black hover:text-gold"
                  }`}
                >
                  <div className="flex w-full flex-row  gap-3 text-sm/6  pl-3 pr-1 items-center justify-between">
                    <div className="font-medium uppercase">
                     {option.display_name ? (
                        <p>{option.display_name}</p>
                     ) :
                     (
                      <div>
                          {option.session_count > 1  && option.session_count + ' x'} {duration.minutes} minutes
                      </div>
                     )}
                    </div>
                    <p className="font-semibold">{option.price} €</p>
                    <CheckCircleIcon className={`h-6 w-6 fill-white ${isSelected ? "opacity-100" : "opacity-0"}`} />
                  </div>
                </Radio>
              );
            })
          )}
        </RadioGroup>
      </div>
    </div>
  );
};

export default Duration;