import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { PackService } from "../../services/packService";
import { UserPack } from "../../models/UserPack";


// Define state structure
interface PackState {
  packs: UserPack[];
  selectedPack: UserPack | null;
  loading: boolean;
  error: string | null;
}

const initialState: PackState = {
  packs: [],
  selectedPack: null, 
  loading: false,
  error: null,
};


export const fetchUserPacks = createAsyncThunk(
  "packs/fetchUserPacks",
  async ({ customer_id }: { customer_id?: number }, { rejectWithValue }) => {
    try {
      const packs = await PackService.getUserPacks(customer_id);
      return packs;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const packSlice = createSlice({
  name: "packs",
  initialState,
  reducers: {
    setSelectedPack(state, action: PayloadAction<UserPack>) {
      state.selectedPack = action.payload; 
    },
    clearSelectedPack(state) {
      state.selectedPack = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserPacks.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserPacks.fulfilled, (state, action: PayloadAction<UserPack[]>) => {
        state.packs = action.payload;
        state.loading = false;
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .addCase(fetchUserPacks.rejected, (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export const { setSelectedPack, clearSelectedPack } = packSlice.actions;

export default packSlice.reducer;