import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoxOpen, faCalendarPlus } from "@fortawesome/free-solid-svg-icons";
import { AppDispatch, RootState } from "../../store";
import { useNavigate } from "react-router-dom";
import { fetchUserPacks, setSelectedPack } from "../../store/userPacks/packListSlice";
import { UserPack } from "../../models/UserPack";
import { Customer } from "../../models/customer";

interface UserPacksProps {
  selectedClient: Customer;
}

const UserPacks: React.FC<UserPacksProps> = ({ selectedClient }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { packs, loading, error } = useSelector((state: RootState) => state.userPacks);
  const router = useNavigate();

  useEffect(() => {
      dispatch(fetchUserPacks({ customer_id: selectedClient.id }));
  }, [dispatch]);

  const handleBookSession = (pack: UserPack) => {
    dispatch(setSelectedPack(pack));
    router(`/espace-member/booking/${pack.latest_booking_id}/schedule-session`);
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <h2 className="text-xl font-bold mb-4">
        <FontAwesomeIcon icon={faBoxOpen} className="mr-2 text-gold" /> Mes Sessions
      </h2>

      {loading && <p className="text-gray-500">Chargement des packs...</p>}
      {error && <p className="text-red-500">Erreur: {error}</p>}

      {!loading && packs.length === 0 && <p className="text-gray-500">Aucun pack disponible.</p>}

      <ul className="mt-4 space-y-4">
        {packs.map((pack) => (
          <li key={pack.offer_pricing_id} className="border p-3 rounded-lg flex justify-between items-center">
            <div>
              <h3 className="text-lg font-bold">{pack.offer_name} - {pack.experience_name}</h3>
              <p className="text-sm text-gray-500">
                Sessions utilisées: {pack.sessions_booked} / {pack.total_sessions}
              </p>
            </div>
            {pack.sessions_remaining > 0 ? (
              <button onClick={() => handleBookSession(pack)} className="bg-gold text-white px-4 py-2 rounded-md flex items-center gap-2 hover:bg-black hover:text-gold transition">
                <FontAwesomeIcon icon={faCalendarPlus} />
                Réserver une session
              </button>
            ) : (
              <span className="text-gray-400">Aucune session restante</span>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default UserPacks;