import { Offer } from "../models/offer";

export interface SelectedOfferDto extends Offer {
  session_count: number;
  duration_id: number;
  experience_id: number;
}



export const initialSelectedOffer: SelectedOfferDto = {
  id: 0,
  name: '',
  price: 0,
  duration: 0,
  is_kid_friendly: false,
  is_pack: false,
  simulator_count: 0,
  description: '',
  additional_info: '',
  age: '',
  experiences: '',
  min_driver: 0,
  session_count:0,
  duration_id: 0,
  experience_id: 0,
  offer_pricing_id: 0,
}