import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { Booking } from '../../models/booking';
import { BookingService } from '../../services/bookingService';
import { RootState } from '../store';
import { setupInterceptors } from '../../services/apiClient';

// Define the initial state of the slice
interface BookingState {
  bookings: Booking[];
  currentBooking: Booking | null;
  loading: boolean;
  error: string | null;
}

const initialState: BookingState = {
  bookings: [],
  currentBooking: null,
  loading: false,
  error: null,
};

// Async action to fetch bookings
export const fetchBookings = createAsyncThunk(
  'bookings/fetchBookings',
  async (date: string, { getState, dispatch, rejectWithValue }) => {

    // Inject dispatch and getState into apiClient's interceptor
    setupInterceptors(dispatch, getState);

    try {

      // Try fetching bookings with the existing access token
      try {
        const bookings = await BookingService.getBookingsByDate(date);
        return bookings;
      } catch (error: any) {
        /*
        if (error.retry) {
          // Try refreshing the token if the error indicates retry
          const refreshResponse = await dispatch(refreshAccessToken());

          if (refreshResponse.payload) {
            const newAccessToken = refreshResponse.payload as string;

            // Retry the original request with the new token
            const bookings = await BookingService.getBookingsByDate(date, newAccessToken);
            return bookings;
          } else {
            // Logout if refresh failed
            dispatch(logout());
            return rejectWithValue('Failed to refresh token. Please log in again.');
          }
        }
          */

        throw error;  // Throw the error if it's not a 401
      }

    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const fetchBookingDetails = createAsyncThunk(
  "bookingList/fetchBookingDetails",
  async (bookingId: string, { getState, dispatch, rejectWithValue }) => {
   // Inject dispatch and getState into apiClient's interceptor
   setupInterceptors(dispatch, getState);

   try {

     // Try fetching bookings with the existing access token
     try {
       const bookings = await BookingService.getBookingById(bookingId);
       return bookings;
     } catch (error: any) {
       throw error;  // Throw the error if it's not a 401
     }

   } catch (err: any) {
     return rejectWithValue(err.message);
   }
  }
);

// Create the booking slice
const bookingSlice = createSlice({
  name: 'bookings',
  initialState,
  reducers: {
    updateBookingCustomers(state, action: PayloadAction<CustomerDto[]>) {
      if (state.currentBooking) {
        state.currentBooking.customers = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBookings.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBookings.fulfilled, (state, action: PayloadAction<Booking[]>) => {
        state.bookings = action.payload;
        state.loading = false;
      })
      .addCase(fetchBookings.rejected, (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loading = false;
      })

      // Handle fetchBookingDetails for a specific booking
      .addCase(fetchBookingDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBookingDetails.fulfilled, (state, action: PayloadAction<Booking>) => {
        state.currentBooking = action.payload; // Store the fetched booking details
        state.loading = false;
      })
      .addCase(fetchBookingDetails.rejected, (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export const { updateBookingCustomers } = bookingSlice.actions;

export default bookingSlice.reducer;