import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { removeItemFromCart, updateCartItemAsBooked } from '../store//cart/cartSlice'; // Assuming you have these actions defined
import { Link, useNavigate } from 'react-router-dom';
import { TrashIcon } from '@heroicons/react/24/solid';
import { bookedError, bookOffer } from '../utils/booked';
import { formatDateTime } from '../utils/formatDateTime';
import { RootState } from '../store';
import { CartItem } from '../store/stateModel/stateModel';
import LoadingOverlay from '../components/loadingOverlay/LoadingOverlay';

const CartPage: React.FC = () => {
  const cartItems = useSelector((state: RootState) => state.cart.items) as CartItem[];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleRemoveItem = (index: number) => {
    dispatch(removeItemFromCart(index));
  };


  const discountTotal = cartItems.reduce((total, item) => {
    const finalTotal = item.finalTotal ?? item.selectedOffer.price * item.groupSize;
    return total + finalTotal;
  }, 0);

  const grandTotal = cartItems.reduce((total, item) => {
    const finalTotal = item.selectedOffer.price * item.groupSize;
    return total + finalTotal;
  }, 0);

  const handleProceedToCheckout = async () => {
    let allItemsBooked = true;
    setIsLoading(true);

    for (let index = 0; index < cartItems.length; index++) {
      const item = cartItems[index];
      if (!item.isBooked) {
        try {
          const formattedDateTime = item.date
          ? formatDateTime(`${item.date}T${item.time}`)
          : undefined;

          const additionalBookingData = await bookOffer({
            customers: item.customers,
            eventDetails: item.eventDetails,
              ...(formattedDateTime && { bookingdate: formattedDateTime }),
            groupSize: item.groupSize,
            childrenSize: item.childrenSize,
            offer: item.selectedOffer,
          });

          // Update the cart item as booked
          dispatch(updateCartItemAsBooked({ index, bookingData: additionalBookingData }));
        } catch (error) {
          console.error('Error booking item:', error);
          allItemsBooked = false;
          const messageError = bookedError(error);
          dispatch(updateCartItemAsBooked({ index, error: messageError }));
          break;
        }
      }
    }

    setIsLoading(false);

    if (allItemsBooked) {
      navigate('/checkout');
    }
  };

  return (
    <div className="container mx-auto md:p-6 pt-6 relative">
      <div className="flex justify-between items-center mb-10">
        <h1 className="text-2xl md:text-3xl font-bold">Votre Panier</h1>
        <Link to="/experience" className="text-gold hover:text-black">
          Continuer les achats
        </Link>
      </div>
      {cartItems.length > 0 ? (
        <div className="bg-white p-6 rounded-lg shadow-md">
          <div className="grid grid-cols-3 gap-4 mb-6 border-b pb-2">
            <div className="text-gray-600 font-semibold">Offre</div>
            <div className="text-gray-600 font-semibold text-center">Nombre de pilotes</div>
            <div className="text-gray-600 font-semibold text-right">Total</div>
          </div>
          {cartItems.map((item, index) => (
            <div key={index} className="grid grid-cols-3 gap-4 items-center border-b py-2">
              <div className="flex items-center">
                <div>
                  <h2 className="text-md font-semibold">{item.selectedOffer.name}</h2>
                  <p className="text-gray-500">{item.selectedOffer.price}€</p>
                </div>
              </div>
              <div className="flex items-center justify-center">
                <div className="text-md">{item.groupSize}</div>
                <button className="text-gray-500 hover:text-gray-700 mx-2" onClick={() => handleRemoveItem(index)}>
                  <TrashIcon className="h-5 w-5" />
                </button>
              </div>
              <div className="font-semibold text-right">
                {item.finalTotal && item.finalTotal !== item.originalTotal ? (
                  <div>
                    <span className="text-green-700 pr-2">{item.finalTotal}€</span>
                    <span className="line-through text-red-500">{item.originalTotal}€</span>
                  </div>
                ) : (
                  <span className="text-gray-900">{item.originalTotal}€</span>
                )}
                {item.error && <p className="text-red-600 text-sm mt-2">{item.error}</p>}
              </div>
            </div>
          ))}
          <div className="mt-6 flex justify-end items-center">
            <div className="text-right">
              <p className="font-semibold">
                Total: <span className={`text-gray-500 ${discountTotal !== grandTotal ? 'line-through text-red-500' : 'text-gray-900'}`}>{grandTotal.toFixed(2)}€</span>
              </p>
              {discountTotal !== grandTotal && (
                <p className="font-semibold">Total remisé: <span className="text-green-700">{discountTotal.toFixed(2)}€</span></p>
              )}

              <button
                onClick={handleProceedToCheckout}
                disabled={isLoading}
                className="bg-gold text-black py-3 px-10 hover:bg-black hover:text-gold mt-4 inline-block"
              >
                Procéder au paiement
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <p className="text-center text-gray-500">Votre panier est vide.</p>
          <div className="flex justify-center">
            <Link to="/experience" className="bg-gold text-black py-3 px-10 hover:bg-black hover:text-gold mt-4 inline-block">
              Continuer les achats
            </Link>
          </div>
        </div>
      )}
      <LoadingOverlay isLoading={isLoading} />
    </div>
  );
};

export default CartPage;