import React from "react";
import { Offer } from "../../models/offer";

interface OfferCardProps {
  onOfferChange: (offer: any) => void;
  offer: Offer;
  index: number;
}

const OfferCard: React.FC<OfferCardProps> = ({
  onOfferChange,
  offer,
  index
}) => {

  const {
    id,
    name,
    description,
    age,
    duration,
    price,
    image,
    bestSeller,
    offer_pricing_id,
    is_kid_friendly
  } =  offer;


  const handleOfferSelection = () => {
    

    const selectedOffer = offer;
    onOfferChange(selectedOffer);
  }

  return (
    <div 
   
    className="bg-white rounded-lg shadow-lg overflow-hidden">
      {/* Offer Image */}
      <div className="relative">
        <img src={`images/mode-de-jeu/${image}.jpg`} alt={name} className="w-full h-64 object-cover" />
        {bestSeller && (
          <span className="absolute top-3 left-3 bg-gold text-black px-2 py-1 text-sm font-bold">
            BEST SELLER
          </span>
        )}
        <span className="absolute bottom-3 left-3 bg-black text-white px-2 py-1 text-sm rounded-full">
        {age}
        </span>
      </div>

      {/* Offer Details */}
      <div className="bg-black text-white p-6 flex flex-col h-full">

        <h3 className="text-xl font-bold uppercase">{name}</h3>
        <p className="mt-2 text-sm text-gray-400">
          {duration} minutes | {age} | {price}€ {offer_pricing_id}
        </p>
        <p className="mt-4 text-sm">{description}</p>

        <div className="mt-6 flex justify-between items-center border-t border-gray-700 pt-4">
          <a href="#" className="text-sm text-gray-400 hover:text-gold">
            VOIR PLUS
          </a>
          <button
              onClick={handleOfferSelection}
              className="bg-gold text-white px-4 py-2 text-sm font-bold rounded-md hover:bg-black hover:text-gold border-2 border-transparent hover:border-gold transition"
            >
            RÉSERVER
            </button>
        </div>
      </div>
    </div>
  );
};

export default OfferCard;