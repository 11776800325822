import { AxiosResponse } from 'axios';
import apiClient from './apiClient'; // Assuming you have this service handling axios with interceptors
import { Customer } from '../models/customer';
import { Offer } from '../models/offer';
import { Booking } from '../models/booking';
import { BookingDataDto } from '../dto/bookingDto';

// Base URL from environment variables
const BASE_URL = process.env.REACT_APP_API_URL as string;


interface BookingResponse {
  message: string
  booking: Booking
}

interface CustomerResponse {
  message: string
  customer: Customer
}

// Check customer by email
export const checkCustomer = async (email: string): Promise<Customer | null> => {
  try {
    const response: AxiosResponse<{ customer: Customer | null }> = await apiClient.get(`${BASE_URL}/customers`, {
      params: { email },
    });
    return response.data.customer;
  } catch (error) {
    console.error('Error checking customer:', error);
    throw error;
  }
};

// Create a new customer
export const createCustomer = async (customerData: CustomerDto): Promise<Customer> => {
  try {
    const response: AxiosResponse<CustomerResponse> = await apiClient.post(`${BASE_URL}/customers`, customerData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data.customer;
  } catch (error) {
    console.error('Error creating customer:', error);
    throw error;
  }
};

// Update a customer
export const updateCustomer = async (customerId: number, customerData: CustomerDto): Promise<Customer> => {
  try {
    const response: AxiosResponse<CustomerResponse> = await apiClient.put(`${BASE_URL}/customers/${customerId}`, customerData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data.customer;
  } catch (error) {
    console.error('Error updating customer:', error);
    throw error;
  }
};

// Create a new booking
export const createBooking = async (bookingData: BookingDataDto): Promise<BookingResponse> => {
  try {
    const response: AxiosResponse<BookingResponse> = await apiClient.post(`${BASE_URL}/bookings`, bookingData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error creating booking:', error);
    throw error;
  }
};

// Update a booking times
export const updateBookingTimes = async (bookingId: number, bookingData: any): Promise<any> => {
  try {
    const response: AxiosResponse<CustomerResponse> = await apiClient.put(`${BASE_URL}/bookings/${bookingId}/times`, bookingData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error updating booking times:', error);
    throw error;
  }
};

// Get available offers
export const getOffers = async (): Promise<Offer[]> => {
  try {
    const response: AxiosResponse<Offer[]> = await apiClient.get(`${BASE_URL}/offers`);
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch offers');
  }
};

// Get available time slots
export const getAvailableTimeSlots = async (
  date: string,
  offerId: number,
  groupSize: number,
  venueId: number
): Promise<AvailableSlotsResponse> => {
  try {
    const response: AxiosResponse<AvailableSlotsResponse> = await apiClient.get(`${BASE_URL}/available-time-slots`, {
      params: { date, offer_id: offerId, group_size: groupSize, venue_id: venueId },
    });
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch available time slots');
  }
};

// Create payment (for Stripe or Sylq)
export const createPayment = async (paymentData: PaymentData): Promise<any> => {
  try {
    const response: AxiosResponse<{ paymentIntentId: string }> = await apiClient.post(`${BASE_URL}/payment`, paymentData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    throw new Error('Failed to create payment');
  }
};

// Update payment status
export const updatePaymentStatus = async (paymentId: string, status: PaymentStatus['status']): Promise<void> => {
  try {
    await apiClient.put(`${BASE_URL}/payments/${paymentId}/status`, { status });
  } catch (error) {
    throw new Error('Failed to update payment status');
  }
};



// Fetch available dates for booking
export const fetchAvailableDates = async (): Promise<string[]> => {
  try {
    const response: AxiosResponse<{ availableDates: string[] }> = await apiClient.get(`${BASE_URL}/calendar/available-dates`, {
      params: {source:'backoffice' },
    });
    return response.data.availableDates;
  } catch (error) {
    throw new Error('Failed to fetch available dates');
  }
};