import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { Customer } from "../../models/customer";
import apiClient from "../../services/apiClient";

export const fetchClients = createAsyncThunk("clients/fetchClients", async () => {
  const response = await apiClient.get<Customer[]>("/clients");
  return response.data;
});

interface ClientState {
  clients: Customer[];
  selectedClient: Customer | null;
  loading: boolean;
  error: string | null;
}

const initialState: ClientState = {
  clients: [],
  selectedClient: null,
  loading: false,
  error: null,
};

const clientListSlice = createSlice({
  name: "clientList",
  initialState,
  reducers: {
    selectClient: (state, action: PayloadAction<Customer>) => {
      state.selectedClient = action.payload;
    },
    clearSelectedClient: (state) => {
      state.selectedClient = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchClients.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchClients.fulfilled, (state, action) => {
        state.clients = action.payload;
        state.loading = false;
      })
      .addCase(fetchClients.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Erreur de récupération des clients.";
      });
  },
});

export const { selectClient, clearSelectedClient } = clientListSlice.actions;
export default clientListSlice.reducer;