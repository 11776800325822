import React from 'react';
import { Booking } from '../../../models/booking'; // Ensure the correct path to the Booking model
import { formatTimeWithoutZone } from '../../../utils/formatDateTime';

interface BookingModalContentProps {
  booking: Booking;
}

const BookingModalContent: React.FC<BookingModalContentProps> = ({ booking }) => {
  return (
    <>
      {/* Client Information */}
      <h3 className="text-lg font-bold text-gray-700 mb-4">Informations du client</h3>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-6">
        <div>
          <p className="font-semibold text-gray-600">Nom:</p>
          <p className="text-gray-700">{booking.customer.firstname} {booking.customer.lastname}</p>
        </div>
        <div>
          <p className="font-semibold text-gray-600">Email:</p>
          <p className="text-gray-700">{booking.customer.email}</p>
        </div>
        <div>
          <p className="font-semibold text-gray-600">Téléphone:</p>
          <p className="text-gray-700">{booking.customer.tel}</p>
        </div>
      </div>

      {/* Booking Information */}
      <h3 className="text-lg font-bold text-gray-700 mb-4">Informations de la réservation</h3>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <div>
          <p className="font-semibold text-gray-600">Offre:</p>
          <p className="text-gray-700">{booking.offer.name}</p>
        </div>
        <div>
          <p className="font-semibold text-gray-600">Expérience:</p>
          <p className="text-gray-700">{booking.offer.experience.name}</p>
        </div>
        <div>
          <p className="font-semibold text-gray-600">Durée:</p>
          <p className="text-gray-700">{booking.offer.duration} min</p>
        </div>
        <div>
          <p className="font-semibold text-gray-600">Nombre de pilotes:</p>
          <p className="text-gray-700">{booking.groupSize} pilote(s)</p>
        </div>
        <div>
          <p className="font-semibold text-gray-600">Sessions utilisées: {booking.sessions_booked}/{booking.total_sessions}</p>
        </div>
      </div>

      {/* Sessions Information */}
      <h3 className="text-lg font-bold text-gray-700 mt-6 mb-4">Sessions planifiées</h3>
      {booking.sessions.length > 0 ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          {booking.sessions.map((session, index) => (
            <div key={session.bookingSessionId} className="border p-3 rounded-lg bg-gray-100">
              <p className="font-semibold text-gray-600">Session {index + 1}:</p>
              <p className="text-gray-700">
                <span className="font-semibold">Date:</span> {new Date(session.startTime).toLocaleDateString("fr-FR")}
              </p>
              <p className="text-gray-700">
                <span className="font-semibold">Heure:</span> {formatTimeWithoutZone(session.startTime)}
              </p>
            </div>
          ))}
        </div>
      ) : (
        <p className="text-gray-700 italic">Aucune session planifiée pour le moment.</p>
      )}
    </>
  );
};

export default BookingModalContent;