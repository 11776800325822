// booked.ts

import { addMinutes, parseISO } from 'date-fns';
import { createBooking } from '../services/apiService';
import { formatDateTime } from './formatDateTime';
import { Booking } from '../models/booking';
import { SelectedOfferDto } from '../dto/SelectedOfferDto';
import { BookingDataDto } from '../dto/bookingDto';



// Book an offer
export const bookOffer = async ({
  customers,
  eventDetails,
  bookingdate,
  groupSize,
  childrenSize,
  offer,
}: {
  customers: CustomerDto[];
  eventDetails: EventDetails;
  bookingdate?: string;
  groupSize: number;
  childrenSize: number;
  offer: SelectedOfferDto;
}): Promise<Booking> => {

  const primaryCustomerId = customers[0].id
  const participants = customers.length > 1 ? customers.slice(1).map(customer => ({ customer_id: customer.id })) : null;

  const bookingData: BookingDataDto = {
    event_name: eventDetails.eventName,
    customer_id: primaryCustomerId || 0,
    offer_id: offer.id || 0,
    status: 'Holding',
    venue_id: eventDetails.venueId,
    group_size: groupSize,
    children_size: childrenSize,
    session_count: offer.session_count,
    duration_id: offer.duration_id,
    experience_id: offer.experience_id,
    ...(bookingdate
      ? {
          start_time: bookingdate,
          end_time: formatDateTime(
            `${addMinutes(parseISO(bookingdate), offer.duration)}`
          ),
        }
      : {}),
      ...(participants && participants.length  && { participants })
  };

  const data = await createBooking(bookingData);
  return data.booking;
};

// Handle booking errors
export const bookedError = (error: any): string => {
  let message = 'La création de la réservation a échoué.';
  const codesErrors = ['BOOKING_CONFLICT', 'SIMULATOR_UNAVAILABLE'];

  if (error && typeof error === 'object' && 'response' in error) {
    if ('code' in error.response.data && codesErrors.includes(error.response.data.code)) {
      message = error.response.data.message;
    }
  }

  return message;
};