import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const login = async (email: string, password: string) => {
  const source = "backoffice";
  const response = await axios.post(`${API_URL}/login`, { email, password, source});
  return response.data;  // Contains accessToken and refreshToken
};

// You can add other auth-related methods like refreshing tokens here