import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { storePurchaseDetails } from '../../store/purchase/purchaseSlice';
import { clearCart, selectBookingIds, selectCustomerId } from '../../store/cart/cartSlice';
import { createPayment } from '../../services/apiService';
import { RootState } from '../../store'; // Assuming you have RootState defined
import { Radio, RadioGroup } from '@headlessui/react'
import { CheckCircleIcon } from '@heroicons/react/24/solid'
import LoadingOverlay from '../loadingOverlay/LoadingOverlay';

interface CheckoutFormProps {
  finalTotal: number;
}


const paymentProviders = [
  { name: 'Paiement CB', provider: 'paiement_cb' },
  { name: 'Paiement Espèce', provider: 'paiement_espece' },
  { name: 'Paiement Crypto', provider: 'paiement_crypto' },
  { name: 'Offert Vip', provider: 'paiement_offert_vip' },
  { name: 'Offert Détection de talents', provider: 'paiement_offert_talents' },
  { name: 'Formation', provider: 'paiement_formation' },
 
]

const CheckoutForm: React.FC<CheckoutFormProps> = ({ finalTotal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const cartItems = useSelector((state: RootState) => state.cart.items);
  const bookingIds = useSelector(selectBookingIds);
  const customerId = useSelector(selectCustomerId);

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [paymentSuccesId, setPaymentSuccesId] = useState<string | null>(null);


  const [selectedProvider, setSelectedProvider] = useState(paymentProviders[0])



  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
   

    if (!selectedProvider) {
      setError('Merci de choisir une methode de paiement')
      return
    }

    setLoading(true);

    try {
      const paymentData = {
        amount: finalTotal,
        customerId: customerId!,
        bookingIds: bookingIds!,
        provider: selectedProvider.provider
      };

      const paymentResponse = await createPayment(paymentData);
      const paymentId = paymentResponse.data.paymentId;

      if (paymentId) {
        setPaymentSuccesId(paymentId);
        setPaymentSuccess(true);
      }


    } catch (error: any) {
      setError('Error initiating payment');
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    if (paymentSuccess) {
      dispatch(storePurchaseDetails({
        cartItems: cartItems,
        finalTotal: finalTotal,
        provider: selectedProvider.provider,
        ...(paymentSuccesId && {paymentIntentId: paymentSuccesId } )
      }));

      dispatch(clearCart());
      navigate('/order-confirmation');
    }
  }, [paymentSuccess, dispatch, navigate]);


  return (
    <div className="mx-auto p-6 bg-white rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold text-gray-900 mb-4"> Procéder au paiement</h2>

      {paymentSuccess ? (
        <div className="text-center">
          <h2 className="text-2xl font-bold text-gold">Merci pour votre réservation!</h2>
          <p className="mt-4 text-gray-700">Votre paiement a été traité avec succès.</p>
        </div>
      ) : (
        <div>
       
          <form onSubmit={handleSubmit} className="mb-4">
            
          <div className="w-full">
            <div className="mx-auto w-full my-6">
              <RadioGroup value={selectedProvider} onChange={setSelectedProvider} aria-label="Payement Provider" className="space-y-2">
                {paymentProviders.map((provider) => (
                  <Radio
                    key={provider.name}
                    value={provider}
                    className="group relative flex cursor-pointer border-gray-300 border rounded-lg bg-white-500 py-3 px-5 pr-2 shadow-md transition focus:outline-none data-[focus]:outline-1 data-[focus]:outline-white data-[checked]:border-gold data-[checked]:border-2"
                    >
                    <div className="flex w-full items-center justify-between text-gray-600">
                      <div className="text-sm/6">
                        <p className="font-semibold">{provider.name}</p>
                        
                      </div>
                      <CheckCircleIcon className="size-6 fill-gold opacity-0 transition group-data-[checked]:opacity-100" />
                    </div>
                  </Radio>
                ))}
              </RadioGroup>
            </div>
          </div>




            {error && <div className="text-red-600 mb-4">{error}</div>}
            <button
              type="submit"
              disabled={loading}
              className={`w-full py-2 px-4 rounded-md text-black ${loading ? 'bg-gray-400' : 'bg-gold hover:bg-black hover:text-gold'} focus:outline-none`}
            >
              {loading ? 'Traitement...' : 'Paiement effectué'}
            </button>
            <LoadingOverlay isLoading={loading} />
          </form>
        </div>
      )}
    </div>
  );
};

export default CheckoutForm;