// src/components/CustomerForm/CustomerForm.tsx
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { createCustomer, updateCustomer } from '../../services/apiService';
import 'react-datepicker/dist/react-datepicker.css';
import LoadingOverlay from '../loadingOverlay/LoadingOverlay';
import { toCustomerDto } from '../../adapters/CustomerAdapter';


interface CustomerFormProps {
  onCustomerChange: (field: keyof CustomerDto, value: string) => void;
  onCustomerCreated: (customerData: CustomerDto) => void;
  eventDetails: EventDetails;
  customerExist?: CustomerDto;
  externalErrors?: { [key: string]: string };
  searchedEmail?: string;
  iterationIndex: number;
  required: boolean;
  isFromParticipantsPage?: boolean; 
  bookingId?: number;
  sessionId?: number;
}

const CustomerForm: React.FC<CustomerFormProps> = ({
  onCustomerChange,
  onCustomerCreated,
  eventDetails,
  customerExist,
  externalErrors,
  searchedEmail,
  iterationIndex,
  required,
  isFromParticipantsPage = false,
  bookingId,
  sessionId
}) => {



  const [firstname, setFirstName] = useState<string>(customerExist?.firstname || '');
  const [lastname, setLastName] = useState<string>(customerExist?.lastname || '');
  const [tel, setTel] = useState<string>(customerExist?.tel || '');
  const [email, setEmail] = useState<string>(customerExist?.email || searchedEmail || '');
  const [birthday, setBirthday] = useState<string>(customerExist?.birthday || '');
  const [civilite, setCivilite] = useState<string>(customerExist?.civilite || '');
  const [consent, setConsent] = useState((customerExist ? (customerExist.event_notifications  ? 'oui' : 'non') : ''));
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [eventNotifications, setEventNotifications] = useState<boolean>((customerExist ? (customerExist.event_notifications  ? true : false) : true));
  const homeVenueId = eventDetails.venueId


  const requierdField: boolean = isFromParticipantsPage ? true : (iterationIndex < 1 ? true : false)


  const formatDate = (dateString: string) => new Date(dateString).toLocaleDateString("fr-FR");

  const validateField = (name: string, value: string): string => {
    if (!value) {
      return 'Ce champ est requis';
    }
    return '';
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (name === 'firstname') setFirstName(value);
    if (name === 'lastname') setLastName(value);
    if (name === 'tel') setTel(value);
    if (name === 'email') setEmail(value);

    const inputName: keyof CustomerDto = 
    ['firstname', 'lastname'].includes(name) ? 'name' as keyof CustomerDto : name as keyof CustomerDto;

    onCustomerChange(inputName, value);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const error = validateField(name, value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error || (externalErrors && externalErrors[name]) || '',
    }));
  };


  const handleDateChange = (date: Date | null, event?: React.SyntheticEvent) => {
    if (date) {
      const formattedDate = date.toISOString().split('T')[0];
      setBirthday(formattedDate);
      onCustomerChange('birthday', formattedDate);
    }
  };
  

  const handleConsentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = e.target.value;
    setConsent(selectedValue);
    setEventNotifications(selectedValue === 'oui')
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

   

    const newErrors = {
      firstname: validateField('firstname', firstname),
      lastname: validateField('lastname', lastname),
      email: requierdField ? validateField('email', email) : '',
      birthday: requierdField ? validateField('birthday', birthday) : '',
      civilite: validateField('civilite', civilite),
      ...( requierdField && {consent: validateField('consent', consent)}),
    };

    setErrors(newErrors);

    const hasErrors = Object.values(newErrors).some((error) => error !== '');
    if (hasErrors) {
      return;
    }

   setLoading(true);
    try {
      const customerData: CustomerDto = {
        name: firstname.concat(" ", lastname),
        firstname,
        lastname,
        tel,
        email,
        birthday,
        civilite,
        event_notifications: consent === 'oui',
        home_venue_id: homeVenueId,
        ...(bookingId && {bookingId}),
        ...(sessionId && {sessionId})
      };

      if (isFromParticipantsPage && customerExist?.id) {
        const updatedCustomer = await updateCustomer(customerExist.id, customerData);
        const customerUpdate: CustomerDto = toCustomerDto(updatedCustomer);
        onCustomerCreated(customerUpdate);
      } else {
        const data = await createCustomer(customerData);
        const customerCreate: CustomerDto = toCustomerDto(data)
        onCustomerCreated(customerCreate);
      }

     


      setIsSubmitted(true);
    } catch (err) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        general: 'Erreur lors de la création du client',
      }));
    } finally {
      setLoading(false);
    }
  };

  if (isSubmitted) {
    return (
      <div className="p-4 border-2 border-gold rounded-md">
        <h2 className="text-lg font-medium text-gold">Inscription validée</h2>
        <p><strong>Nom:</strong> {firstname} {lastname}</p>
        <p><strong>Email:</strong> {email}</p>
        <p><strong>Téléphone:</strong> {tel}</p>
        <p><strong>Date de naissance:</strong> {formatDate(birthday)}</p>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit} className="my-4 space-y-4 text-black" noValidate>
      <div className="mb-4 md:mb-0">
        <div className="flex">
          <label className="block text-sm font-medium text-gray-700">Civilité <span className='text-red-500'>*</span></label>
          <div className="mt-0 ml-4">
            <label className="inline-flex items-center">
              <input type="radio" name="civilite" value="Mr" onChange={(e) => setCivilite(e.target.value)}   checked={civilite === "Mr"}/>
              <span className="ml-2">Mr</span>
            </label>
            <label className="inline-flex items-center ml-2">
              <input type="radio" name="civilite" value="Mme" onChange={(e) => setCivilite(e.target.value)}  checked={civilite === "Mme"}/>
              <span className="ml-2">Mme</span>
            </label>
          </div>
        </div>
        {errors.civilite && <p className="text-red-500 text-sm">{errors.civilite}</p>}
      </div>

      <div className="md:flex gap-3">
        <div className="mb-4 md:mb-0 md:w-64">
          <label htmlFor="firstname" className="block text-sm font-medium text-gray-700">
            Prénom <span className='text-red-500'>*</span>
          </label>
          <input
            type="text"
            id="firstname"
            name="firstname"
            className="mt-1 shadow text-black border block w-full pl-3 pr-3 py-2 text-base border-gray-300 focus:outline-none focus:ring-gold focus:border-gold sm:text-sm rounded-md"
            value={firstname}
            onChange={handleInputChange}
            onBlur={handleBlur}
          />
          {errors.firstname && <p className="text-red-500 text-sm">{errors.firstname}</p>}
        </div>

        <div className="mb-4 md:mb-0 md:w-64">
          <label htmlFor="lastname" className="block text-sm font-medium text-gray-700">
            Nom <span className='text-red-500'>*</span>
          </label>
          <input
            type="text"
            id="lastname"
            name="lastname"
            className="mt-1 shadow text-black border block w-full pl-3 pr-3 py-2 text-base border-gray-300 focus:outline-none focus:ring-gold focus:border-gold sm:text-sm rounded-md"
            value={lastname}
            onChange={handleInputChange}
            onBlur={handleBlur}
          />
          {errors.lastname && <p className="text-red-500 text-sm">{errors.lastname}</p>}
        </div>
      </div>

      <div className="md:flex gap-3">
        <div className="mb-4 md:mb-0 md:w-64">
          <label htmlFor="tel" className="block text-sm font-medium text-gray-700">
            Téléphone
          </label>
          <input
            type="text"
            id="tel"
            name="tel"
            className="mt-1 shadow text-black border block w-full pl-3 pr-3 py-2 text-base border-gray-300 focus:outline-none focus:ring-gold focus:border-gold sm:text-sm rounded-md"
            value={tel}
            onChange={handleInputChange}
          />
          {errors.tel && <p className="text-red-500 text-sm">{errors.tel}</p>}
        </div>

        <div className="mb-4 md:mb-0 md:w-64">
          <label htmlFor="email" className="block text-sm font-medium text-gray-700">
            Email {requierdField && <span className='text-red-500'>*</span>}
          </label>
          <input
            type="email"
            id="email"
            name="email"
            className="mt-1 shadow text-black border block w-full pl-3 pr-3 py-2 text-base border-gray-300 focus:outline-none focus:ring-gold focus:border-gold sm:text-sm rounded-md"
            value={email}
            onChange={handleInputChange}
            onBlur={handleBlur}
            disabled={!!customerExist} // Disable email input if customer already exists
            />
            {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
          </div>
        </div>
  
        <div className="mb-4">
          <label htmlFor="birthday" className="block text-sm font-medium text-gray-700">
            Date de naissance {requierdField && <span className='text-red-500'>*</span>}
          </label>
          <DatePicker
            selected={birthday ? new Date(birthday) : null}
            onChange={handleDateChange}
            dateFormat="dd/MM/yyyy"
            locale="fr"
            className="mt-1 shadow text-black border block w-full pl-3 pr-3 py-2 text-base border-gray-300 focus:outline-none focus:ring-gold focus:border-gold sm:text-sm rounded-md"
            placeholderText="Sélectionnez une date"
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            required={requierdField}
          />
          {errors.birthday && <p className="text-red-500 text-sm">{errors.birthday}</p>}
        </div>
  
       
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Souhaitez-vous être informé(e) de l’ouverture prochaine d’E-motion Paris, des évènements et compétitions ? {requierdField && <span className='text-red-500'>*</span>}</label>
            <div className="mt-2">
              <label className="inline-flex items-center">
                <input type="radio" name="consent" value="oui" onChange={handleConsentChange} checked={customerExist && eventNotifications === true}/>
                <span className="ml-2">Oui</span>
              </label>
              <label className="inline-flex items-center ml-2">
                <input type="radio" name="consent" value="non" onChange={handleConsentChange} checked={customerExist && eventNotifications === false}/>
                <span className="ml-2">Non</span>
              </label>
            </div>
            {errors.consent && <p className="text-red-500 text-sm">{errors.consent}</p>}
        </div>
  
          
  
        <div className="flex justify-end">
          <button
            type="submit"
            className="mt-4 bg-black text-gold py-2 px-4 rounded-md hover:bg-gold hover:text-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gold"
            disabled={loading}
          >
            {loading ? 'Création en cours...' : 'Valider'}
          </button>
        </div>
  
        {errors.general && <p className="mt-2 text-sm text-red-500">{errors.general}</p>}
        <LoadingOverlay isLoading={loading} />
      </form>
    );
  };
  
  export default CustomerForm;