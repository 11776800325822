import React from "react";
import { Customer } from "../../models/customer";

interface ClientModalProps {
  client: Customer;
}

const ClientModal: React.FC<ClientModalProps> = ({ client }) => {
  return (
    <div>
      <h3 className="text-lg font-bold">{client.firstname} {client.lastname}</h3>
      <p>Email: {client.email}</p>
      <p>Téléphone: {client.tel}</p>
      <p>Date de naissance: {new Date(client.birthday).toLocaleDateString("fr-FR")}</p>
    </div>
  );
};

export default ClientModal;