import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';
import CustomerFormsList from '../components/customerFormsList/CustomerFormsList';
import { updateBookingCustomers } from '../store/booking/bookingListSlice';
import { Link, useParams } from 'react-router-dom';
import { ArrowLongLeftIcon } from '@heroicons/react/24/solid';

const ParticipantsPage: React.FC = () => {
  // Access currentBooking directly from the state
  const { currentBooking } = useSelector((state: RootState) => state.bookingList);
  const [customers, setCustomers] = useState<CustomerDto[]>([]);
  const dispatch: AppDispatch = useDispatch();
  const { bookingId, sessionId } = useParams<{ bookingId: string; sessionId?: string }>();

  console.log("Booking ID:", bookingId);
  console.log("Session ID:", sessionId);


useEffect(() => {
  if (currentBooking && currentBooking.sessions) {
    const session = currentBooking.sessions.find((s) => s.bookingSessionId === Number(sessionId));
    console.log('session', session);
    if (session) {
    }
  }
  
}, [currentBooking, sessionId]);




  if (!currentBooking) {
    return <div>Loading...</div>;
  }

  const eventDetails:EventDetails = {
    eventName: 'MONDIAL AUTO',
    venueId: 1
  };




  const handleCustomersChange = (updatedCustomers: CustomerDto[]) => {
   // setCustomers(updatedCustomers);
    dispatch(updateBookingCustomers(updatedCustomers));
  };


  return (
    <div>
      <div className="flex justify-between items-center mb-10">
        <h1 className="text-2xl md:text-3xl font-bold">Participants</h1>
        <Link to={`/booking-details/${currentBooking.id}`} className="text-gold hover:text-black flex items-center gap-x-1">
          <ArrowLongLeftIcon className="h-4 w-4" /> Retour
        </Link>
      </div>
      {/* Pass currentBooking.customers to CustomerFormsList */}
      <CustomerFormsList 
        groupSize={currentBooking.groupSize}
        customers={currentBooking.customers || []} 
        onCustomersChange={handleCustomersChange}
        eventDetails={eventDetails}
        isFromParticipantsPage={true}
        bookingId = {currentBooking.id}
        sessionId = {Number(sessionId) || 0} 
      />

      {/* Add buttons or actions for saving the participants */}
    </div>
  );
};

export default ParticipantsPage;