import { InvoiceData } from "../models/invoiceModel";
import apiClient from "./apiClient";

export class InvoiceService {
  static async getInvoiceByPaymentId(paymentId: string): Promise<InvoiceData> {
    try {
      const response = await apiClient.get(`/invoices/payment/${paymentId}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching invoice:", error);
      throw error;
    }
  }
}