import React, { useRef } from "react";

import { ArrowLeftIcon, PrinterIcon } from "@heroicons/react/24/solid";
import { useReactToPrint } from "react-to-print";
import { InvoiceData } from "../../models/invoiceModel";

interface InvoiceComponentProps {
  invoice: InvoiceData
}

const InvoiceComponent: React.FC<InvoiceComponentProps> = ({ invoice }) => {
  const invoiceRef = useRef(null);

  const contentRef = useRef<HTMLDivElement>(null);
  const reactToPrintFn = useReactToPrint({ contentRef, documentTitle: `Facture_${invoice.invoice_number}` });



  return (
    
    <div className="p-6 bg-white rounded-lg shadow-md max-w-3xl mx-auto relative" >

      {/* Print Button */}
      <div className="mt-6 flex justify-end hide-on-print absolute right-0 top-[-20px]">
        <button onClick={() => reactToPrintFn()} className="flex items-center text-gold rounded-lg hover:text-black">
          <PrinterIcon className="h-6 w-6 mr-2" />
        </button>
      </div>
        

      <div className="invoice-container" ref={contentRef}>


      <div className="invoice-header">
        {/* Header */}
        <div className="flex justify-between items-center border-b pb-4">
          <div>
            <h1 className="text-xl font-bold text-gray-800">FACTURE</h1>
            <p className="text-sm text-gray-600">{new Date(invoice.created_at).toLocaleDateString("fr-FR")}</p>
            <p className="text-sm font-semibold">Numéro de facture: <span className="text-gray-800">{invoice.invoice_number}</span></p>
          </div>
          
          <img src="/images/logo-emotion-light.png" alt="Logo" className="h-8 hidden show-on-print" />
          
        </div>
      </div>



        <div className="invoice-body">
        

        {/* Client Info */}
        <div className="mt-6">
          <h2 className="text-lg font-bold text-gray-700">CLIENT</h2>
          <p className="text-gray-700">{invoice.customer.email}</p>
          <p className="text-gray-700"> {invoice.customer.firstname} {invoice.customer.lastname}</p>
        </div>

        {/* Table */}
        <div className="mt-6">
          <table className="w-full border-collapse">
            <thead>
              <tr className="border-b bg-gray-100">
                <th className="text-left p-2 font-semibold">Description</th>
                <th className="text-center p-2 font-semibold">Quantité</th>
                <th className="text-right p-2 font-semibold">Montant HT</th>
              </tr>
            </thead>
            <tbody>
              {invoice.bookings.map((booking: any, index: number) => (
                <tr key={index} className="border-b">
                  <td className="p-2">{booking.offer_name} - {booking.experience_name}</td>
                  <td className="p-2 text-center">{booking.group_size}</td>
                  <td className="p-2 text-right">{booking.price}€</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Totals */}
        <div className="mt-6 text-right">
          <p className="">TOTAL HT: <span className="font-bold">{invoice.htAmount}€</span></p>
          <p className="">TVA: <span className="font-bold">{invoice.tvaAmount}€</span></p>
          <p className="text text-gray-800 mt-2">TOTAL TTC: <span className="font-bold">{invoice.total_paid}€</span></p>
        </div>

        </div>

        {/* Footer */}
        <div className="invoice-footer mt-20 pt-4 border-t">
        <div className="flex justify-between items-center">
          <div>
            <p className="text-lg  text-gray-600 font-bold	">E-MOTION</p>
            <p className="text-sm text-gray-600">117, rue Réaumur 75002 PARIS</p>
            <p className="text-sm text-gray-600">contact@e-motion.racing</p>
          </div>
          <img src="/images/emotion-logo.png" alt="Logo" className="h-8 hide-on-print" />
          <img src="/images/logo-emotion-light.png" alt="Logo" className="h-8 hidden show-on-print" />
        </div>

        <div className="text-center mt-3 text-sm text-gray-600">
          <p>E-MOTION N° Siret: {invoice.siret_number}</p>
        </div>
      </div>
       
      </div>
      </div>
      
    
  );
};

export default InvoiceComponent;