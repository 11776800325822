import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import EspaceLayout from "./espace-layout";
import BookingList from "../../components/espace-member/BookingList";
import UserPacks from "../../components/espace-member/UserPacks";

const EspaceMemberDashboard: React.FC = () => {
  const selectedClient = useSelector((state: RootState) => state.clients.selectedClient);
  const navigate = useNavigate();

  useEffect(() => {
    if (!selectedClient) {
      navigate("/clients"); // Redirect back if no client selected
    }
  }, [selectedClient, navigate]);

  if (!selectedClient) {
    return null; // Prevent rendering if no client is selected
  }
  console.log('selectedClient', selectedClient);

  return ( 
    <>
    <EspaceLayout>
      <div className="grid grid-cols-1 gap-6 mt-6">
        <div className="space-y-6">
          <BookingList selectedClient = {selectedClient}/>
          <UserPacks selectedClient = {selectedClient}/> 
        </div>
      </div>
    </EspaceLayout>
    </>
  );
};

export default EspaceMemberDashboard;