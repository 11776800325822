// src/adapters/CustomerAdapter.ts

import { Customer } from "../models/customer";


// Adapter function to convert Customer to CustomerDto
export const toCustomerDto = (customer: Customer): CustomerDto => {
  return {
    id: customer.id,
    civilite: customer.civilite,
    firstname: customer.firstname,
    lastname: customer.lastname,
    name: `${customer.firstname} ${customer.lastname}`, 
    email: customer.email,
    tel: customer.tel,
    birthday: customer.birthday,
    home_venue_id: customer.homeVenueId, 
  };
};

// Adapter function to convert CustomerDto to Customer
export const toCustomer = (customerDto: CustomerDto): Customer => {
  const [firstname, lastname] = customerDto.name.split(' '); 

  return {
    id: customerDto.id,
    civilite: customerDto.civilite,
    firstname: firstname || customerDto.firstname, 
    lastname: lastname || customerDto.lastname,
    email: customerDto.email,
    tel: customerDto.tel,
    birthday: customerDto.birthday,
    homeVenueId: customerDto.home_venue_id,
    created_at: new Date(),
  };
};