import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { formatDate, formatDateTime, formatTimeWithoutZone } from '../../utils/formatDateTime';
import { ArrowLongLeftIcon } from '@heroicons/react/24/solid';
import { BookingService } from '../../services/bookingService';
import { bookedError } from '../../utils/booked';
import ErrorMessage from '../errorMessage/ErrorMessage';
import DatePicker from '../datePicker/DatePicker';
import TimePicker from '../timePicker/TimePicker';
import LoadingOverlay from '../loadingOverlay/LoadingOverlay';
import { BookingSession } from '../../models/bookingSession';
import { BookingFormProps } from '../../models/bookingFormProps';


interface BookingSessionProps {
  currentBooking: BookingFormProps | null;
  selectedSession: BookingSession | null;
  backLink: string;
  onBookingSessionSuccess: (bookingData: any) => void;
}

const BookingSessionForm: React.FC<BookingSessionProps> = ({ currentBooking, selectedSession, backLink, onBookingSessionSuccess }) => {
  // Access currentBooking directly from the state
  const [date, setDate] = useState<string | null>(null);
  const [formattedDate, setFormattedDate] = useState<string | null>(null);
  const [time, setTime] = useState<string | null>(null);
  const [errors, setErrors] = useState<Record<string, string | null>>({});
  const [errorKey, setErrorKey] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');


  useEffect(() => {
    if (selectedSession) {
      setDate(formatDate(new Date (selectedSession?.startTime))); 
      setTime(formatTimeWithoutZone(selectedSession?.startTime))
    }
  }, [selectedSession]);



  if (!currentBooking) {
    return <div>Loading...</div>;
  }


  console.log('Planifier session', date);

  const eventDetails:EventDetails = {
    eventName: 'MONDIAL AUTO',
    venueId: 1
  };

  const handleValidateSlot = async () => {
    const errors = validateSelections();
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      setErrorKey((prevKey) => prevKey + 1);
    } else if(formattedDate) {
      try {
        setLoading(true);
        setError('');
        if (!currentBooking.id || !date || !time) {
          return
        }

        if (currentBooking.id && selectedSession) {
          console.log(`Updating booking ${currentBooking.id}...`);
          await BookingService.updateSession(currentBooking.id, date, time, currentBooking.duration_time, Number(selectedSession.bookingSessionId));
        } else {
          await BookingService.bookSession(currentBooking.id, date, time, currentBooking.duration_time);
        }
        onBookingSessionSuccess(currentBooking);
      } catch (error) {
        const messageError = bookedError(error);
        setError(messageError);
      } finally {
        setLoading(false);
      }
    }
  };

  const validateSelections = (): Record<string, string> => {
    const errors: Record<string, string> = {};
    if (!date) errors.date = 'Veuillez sélectionner une date';
    if (!time) errors.time = 'Veuillez sélectionner une heure';
    return errors;
  };

  const handleDateChange = (date: string) => {
    setDate(date);
    if (time) {
      const formattedDateTime = formatDateTime(`${date}T${time}`);
      setFormattedDate(formattedDateTime);
    }
    setErrors((prevErrors) => ({ ...prevErrors, date: null }));
  };

  const handleTimeChange = (time: string) => {
    setTime(time);
    if (date) {
      const formattedDateTime = formatDateTime(`${date}T${time}`);
      setFormattedDate(formattedDateTime);
    }
    setErrors((prevErrors) => ({ ...prevErrors, time: null }));
  };


  return (
    <div>
      <div className="flex justify-between items-center mb-10">
        <h1 className="text-2xl md:text-3xl font-bold">
          {selectedSession?.bookingSessionId ? (
            <>Modifier session</>
          ) : (
            <>Planifier session</>
          )}
       
          
          </h1>
        <Link to={backLink} className="text-gold hover:text-black flex items-center gap-x-1">
          <ArrowLongLeftIcon className="h-4 w-4" /> Retour
        </Link>
      </div>
      {/* Pass currentBooking.customers to CustomerFormsList */}

      <div className="w-full">
        <div className="md:flex">
          <div className="md:w-3/5 md:pr-10">
            <ErrorMessage message={errors.date} errorKey={errorKey} />
             <DatePicker onDateChange={handleDateChange} selectedDate={date} /> 
          </div>
          <div className="md:w-2/5 md:mt-0 mt-8">
            <ErrorMessage message={errors.time} errorKey={errorKey} />
            <TimePicker
              onTimeChange={handleTimeChange}
              selectedTime={time}
              selectedDate={date}
              offerId={currentBooking.offer_pricing_id}
              groupSize={currentBooking.groupSize}
              venueId={eventDetails.venueId}
            />
          </div>
        </div>
        <div className="flex justify-end mt-5">
          <button
            onClick={handleValidateSlot}
            className="mt-4 bg-gold text-black py-2 px-4 rounded-md hover:bg-black hover:text-gold"
          >
           Valider
          </button>
        </div>
        {error && <p className="mt-2 text-sm text-red-500">{error}</p>}
        <LoadingOverlay isLoading={loading} />
      </div>
      {/* Add buttons or actions for saving the participants */}
    </div>
  );
};

export default BookingSessionForm;