import axios from 'axios';
import { Booking } from '../models/booking';
import { formatDateTime } from '../utils/formatDateTime';
import { addMinutes, parseISO } from 'date-fns';

import apiClient from './apiClient'; 

export class BookingService {
  // Use the environment variable for the base API URL
  private static API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000/api';
  

  // Fetch bookings by date
  static async getBookingsByDate(date: string): Promise<Booking[]> {
    
    const response = await apiClient.get(`/bookings`, {
      params: { date }
    });
    
    return response.data;
  }

  // Create a new booking
  static async createBooking(booking: Booking): Promise<Booking> {
    const response = await axios.post(`${this.API_URL}/bookings`, booking);
    return response.data;
  }

  static async getBookingById(bookingId: string): Promise<Booking> {
    
    const response = await apiClient.get(`/bookings/${bookingId}`);
    
    return response.data;
  }

  static async bookSession(bookingId: number, bookingdate: string, time: string, duration: number) {
    try {
        const startDate = formatDateTime(`${bookingdate}T${time}`);
        const endDate = addMinutes(parseISO(startDate), duration);
        const formattedEndDate = formatDateTime(`${endDate}`);

      const response = await apiClient.post(`/bookings/${bookingId}/sessions`, {
        start_time: startDate,
        end_time: formattedEndDate,
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async updateSession(bookingId: number, bookingdate: string, time: string, duration: number, sessionId: number) {
    try {
        const startDate = formatDateTime(`${bookingdate}T${time}`);
        const endDate = addMinutes(parseISO(startDate), duration);
        const formattedEndDate = formatDateTime(`${endDate}`);

      const response = await apiClient.put(`/bookings/${bookingId}/sessions/${sessionId}`, {
        start_time: startDate,
        end_time: formattedEndDate,
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  }


  static async getAllSessionsByClient(customer_id: number): Promise<Booking[]> {
    
    const response = await apiClient.get(`/getAllSessionsByClient/${customer_id}`);
    
    return response.data;
  }

}