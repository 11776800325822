import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {  useNavigate, useParams } from 'react-router-dom';
import { AppDispatch, RootState } from '../../store';
import { BookingSession } from '../../models/bookingSession';
import BookingSessionForm from '../../components/booking-sessions/BookingSessionForm';
import { BookingFormProps } from '../../models/bookingFormProps';
import { clearSelectedBooking } from '../../store/bookingMemberSessions/bookingMemberSlice';
import { clearSelectedPack } from '../../store/userPacks/packListSlice';

const SessionMemberBookingPage: React.FC = () => {
  // Access currentBooking directly from the state
  const { currentBooking } = useSelector((state: RootState) => state.bookingMembeSession);
  const { selectedPack } = useSelector((state: RootState) => state.userPacks);
  const navigate = useNavigate();
  const { bookingId, sessionId } = useParams<{ bookingId: string; sessionId?: string }>();
  const [selectedSession, setSelectedSession] = useState<BookingSession | null>(null);
  const [selectedBooking, setSelectedBooking] = useState<BookingFormProps | null>(null);
  const dispatch = useDispatch<AppDispatch>();

  console.log("Booking ID:", bookingId);
  console.log("Session ID:", sessionId);

  console.log("currentBooking :", currentBooking);
  console.log("selectedPack :", selectedPack);


  const handleBookingSessionSuccess = async (bookingData: any) => {
     dispatch(clearSelectedBooking());
     dispatch(clearSelectedPack());
    navigate(`/espace-member`); 
  }

  useEffect(() => {
    let notAllowedToAcces = false;
    if (!currentBooking && !selectedPack) {
      notAllowedToAcces = true
    }



    if (currentBooking && currentBooking.sessions && sessionId) {
      const session = currentBooking.sessions.find((s) => s.bookingSessionId === Number(sessionId));
      console.log('session', session);
      if (!session) {
        notAllowedToAcces = true
      } else {
        setSelectedSession(session);
      }
    }

    if (selectedPack?.latest_booking_id) {
      setSelectedBooking(
        {
          id: selectedPack.latest_booking_id,
          offer_pricing_id: selectedPack.offer_pricing_id,
          duration_time: selectedPack.duration_time,
          groupSize: selectedPack.groupSize
        }
      )
    }

    if (currentBooking && sessionId) {
      setSelectedBooking(
        {
          id: currentBooking.id,
          offer_pricing_id: currentBooking.offer_pricing_id,
          duration_time: currentBooking.offer.duration,
          groupSize: currentBooking.groupSize,
        }
      )
    }

    console.log('notAllowedToAcces', notAllowedToAcces)

    if (notAllowedToAcces) {
      navigate(`/espace-member`); 
    }

  }, [currentBooking, navigate, bookingId, sessionId])


  if (!selectedBooking) {
    return <div>Loading...</div>;
  }

  return (
   <BookingSessionForm
      currentBooking = {selectedBooking}
      selectedSession={selectedSession}
      backLink={`/espace-member`}
      onBookingSessionSuccess={handleBookingSessionSuccess}
   />
  );
};

export default SessionMemberBookingPage;