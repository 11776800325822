import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { Booking } from '../../models/booking';
import { BookingService } from '../../services/bookingService';
import { setupInterceptors } from '../../services/apiClient';

// Define the initial state of the slice
interface BookingMemberState {
  bookings: Booking[];
  currentBooking: Booking | null;
  loading: boolean;
  error: string | null;
}

const initialState: BookingMemberState = {
  bookings: [],
  currentBooking: null,
  loading: false,
  error: null,
};

// Async action to fetch bookings
export const fetchBookingsMember = createAsyncThunk(
  'bookings/fetchBookingsMember',
  async (customer_id: number, { getState, dispatch, rejectWithValue }) => {

    // Inject dispatch and getState into apiClient's interceptor
    setupInterceptors(dispatch, getState);

    try {

      try {
        const bookings = await BookingService.getAllSessionsByClient(customer_id);
        return bookings;
      } catch (error: any) {
        throw error;  // Throw the error if it's not a 401
      }

    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const fetchBookingMemberDetails = createAsyncThunk(
  "bookingList/fetchBookingMemberDetails",
  async (bookingId: string, { getState, dispatch, rejectWithValue }) => {
   // Inject dispatch and getState into apiClient's interceptor
   setupInterceptors(dispatch, getState);

   try {

     // Try fetching bookings with the existing access token
     try {
       const bookings = await BookingService.getBookingById(bookingId);
       return bookings;
     } catch (error: any) {
       throw error;  // Throw the error if it's not a 401
     }

   } catch (err: any) {
     return rejectWithValue(err.message);
   }
  }
);

// Create the booking slice
const bookingMemberSlice = createSlice({
  name: 'bookingMemberSessions',
  initialState,
  reducers: {
    updateBookingCustomers(state, action: PayloadAction<CustomerDto[]>) {
      if (state.currentBooking) {
        state.currentBooking.customers = action.payload;
      }
    },
     setSelectedBooking(state, action: PayloadAction<Booking>) {
      state.currentBooking = action.payload; 
    },
    clearSelectedBooking(state) {
      state.currentBooking = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBookingsMember.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBookingsMember.fulfilled, (state, action: PayloadAction<Booking[]>) => {
        state.bookings = action.payload;
        state.loading = false;
      })
      .addCase(fetchBookingsMember.rejected, (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loading = false;
      })

      // Handle fetchBookingMemberDetails for a specific booking
      .addCase(fetchBookingMemberDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBookingMemberDetails.fulfilled, (state, action: PayloadAction<Booking>) => {
        state.currentBooking = action.payload; // Store the fetched booking details
        state.loading = false;
      })
      .addCase(fetchBookingMemberDetails.rejected, (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export const { updateBookingCustomers, setSelectedBooking, clearSelectedBooking  } = bookingMemberSlice.actions;

export default bookingMemberSlice.reducer;